/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-control-bottomcenter {
  top: 110%;
}
